import WrapperContent from '@components/Layout/WrapperContent';
import NewsTimeLine, { PropsTitle } from '@/components/Layout/NewsTimeLine';
import { TimeLine } from '@/types/types';
import Titulo from '@/components/Layout/Title';
import { mglBlue, mglGray } from '@/styles/designSystemColors';

type HighLightCategory = {
  nome: string;
  url: string;
  titulo: string;
  itens: TimeLine[];
};

export interface CategoriesProps {
  featuredCategoriesData: HighLightCategory[];
}

export default function FeaturedCategories({ featuredCategoriesData }: CategoriesProps) {
  const PropTitle: PropsTitle = {
    color: 'darkText',
    altColorInHover: true,
    colorHover: mglBlue.mglBlue400,
  };

  return (
    <WrapperContent backgroundColor={mglGray.mglGray50} paddingOnBottom>
      <Titulo texto={featuredCategoriesData[0].nome} urlRedirect={featuredCategoriesData[0].url} color="black" showChip alignRight />
      <div className="grid grid-cols-1 grid-rows-1 gap-4 [grid-auto-columns:1fr] phone:grid-cols-2 desktop:grid-cols-3 [&_article]:[@media_screen_and_(max-width:767px)]:[grid-column-end:3] [&_article]:[@media_screen_and_(max-width:767px)]:[grid-column-start:1] first:[&_article]:[@media_screen_and_(max-width:992px)]:[grid-column-end:3] first:[&_article]:[@media_screen_and_(max-width:992px)]:[grid-column-start:1]">
        {featuredCategoriesData[0].itens.map(n => (
          <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} headingTag="h3" />
        ))}
      </div>
      <div className="mt-6">
        <div className="grid grid-cols-1 gap-x-[36px] gap-y-6 desktop:grid-cols-2">
          <div>
            <Titulo texto={featuredCategoriesData[1].nome} urlRedirect={featuredCategoriesData[1].url} color="black" showChip alignRight />
            <div className="grid grid-cols-1 grid-rows-1 gap-4 tablet:grid-cols-2">
              {featuredCategoriesData[1].itens.map(n => (
                <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} headingTag="h3" />
              ))}
            </div>
          </div>
          <div>
            <Titulo texto={featuredCategoriesData[2].nome} urlRedirect={featuredCategoriesData[2].url} color="black" showChip alignRight />
            <div className="grid grid-cols-1 grid-rows-1 gap-4 tablet:grid-cols-2">
              {featuredCategoriesData[2].itens.map(n => (
                <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} headingTag="h3" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </WrapperContent>
  );
}
