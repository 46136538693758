import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import WrapperContent from '@components/Layout/WrapperContent';
import Titulo from '@/components/Layout/Title';
import Card from '@/components/Layout/Card';
import { mglGray } from '@/styles/designSystemColors';

SwiperCore.use([Navigation, Pagination, Autoplay]);

type HighLightProducts = {
  titulo: string;
  url: string;
  imagem: {
    url: string;
    blurhash: string;
    ratio?: number;
  };
  rumor: boolean;
};

export interface featuredProducts {
  featuredProductsData: HighLightProducts[];
  title?: string;
  bgColor?: string;
  indexComponent?: string;
  shouldShowChip?: boolean;
  url?: string;
}

export default function FeaturedProducts({
  featuredProductsData,
  title = '',
  bgColor = '',
  indexComponent = '',
  shouldShowChip = false,
  url = '',
}: featuredProducts) {
  const pagination = {
    clickable: true,
  };

  const navigation = {
    nextEl: `#produtos-swiper-button__next${indexComponent}`,
    prevEl: `#produtos-swiper-button__prev${indexComponent}`,
  };

  return (
    <>
      <WrapperContent backgroundColor={bgColor || mglGray.mglGray200} paddingOnBottom>
        <Titulo texto={title || 'Produtos em Alta'} color="black" showChip={shouldShowChip} alignRight={shouldShowChip} urlRedirect={url} />
        <div className="relative mt-4 [&_.container-swiper\_\_next]:-right-2 [&_.container-swiper\_\_prev]:-left-2 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:transition-opacity [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-in-out [&_.swiper-pagination]:hidden desktop:[&_.swiper-pagination]:block [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:w-[26px] [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:bg-mglBlue400 [&_span.swiper-pagination-bullet-active]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s]">
          <Swiper
            centeredSlidesBounds
            speed={1000}
            pagination={pagination}
            navigation={navigation}
            breakpoints={{
              320: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 5,
                slidesPerGroup: 5,
                spaceBetween: 16,
              },
              1660: {
                slidesPerView: 6,
                slidesPerGroup: 6,
                spaceBetween: 16,
              },
            }}>
            {featuredProductsData.map(product => (
              <SwiperSlide key={product.url}>
                <Card title={product.titulo} image={product.imagem} url={product.url} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="container-swiper container-swiper__prev">
            <div id={`produtos-swiper-button__prev${indexComponent}`} className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(180deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
          <div className="container-swiper container-swiper__next">
            <div id={`produtos-swiper-button__next${indexComponent}`} className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(360deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      </WrapperContent>
    </>
  );
}
