import replaceAll from './replaceAll';

export default function getValue(preco: number) {
  const value = preco.toString();
  const price = [];
  const fullPrice = value.split('.')[0] ? value.split('.')[0] : value;
  const priceLength = value.split('.')[0].length ? value.split('.')[0].length : value.length;

  let counter = 0;
  for (let i = priceLength - 1; i >= 0; i -= 1) {
    if (counter !== 0 && counter % 3 === 0) price.push('.');
    counter += 1;
    price.push(fullPrice[i]);
  }

  const getPrice = () => replaceAll(price.reverse().toString(), ',', '');

  if (value.includes('.')) {
    const cents = value.split('.')[1];
    const getCents = () => (cents.length >= 2 ? cents : `${cents}0`);
    return `${getPrice()},${getCents()}`;
  }

  return `${getPrice()},00`;
}
