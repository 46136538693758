import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  DealsBoxWrapper: {
    limitMaxWidth: {
      true: 'p-0',
      false: '[&>div]:w-full [&>div]:max-w-[calc(100%-3rem)] desktop:[&>div]:max-w-[83.33%]',
    },
  },
  DealsBoxTitleContainer: {
    noPadding: {
      true: 'p-0',
      false: '',
    },
  },
  DealsRow: {
    isProduct: {
      true: '',
      false: 'flex-col',
    },
  },
  DealsColumn: {
    isProduct: {
      true: '',
      false: 'isProduct [&.isProduct]:flex-row [&.isProduct]:justify-between [&.isProduct]:items-center [&.isProduct]:w-full',
    },
    fullWidth: {
      true: 'w-full',
      false: '',
    },
  },
  DealsProductDescription: {
    inSwiper: {
      true: 'h-16 phone:h-[4.5rem]',
      false: '',
    },
  },
  DealsSecondaryValue: {
    discount: {
      true: 'line-through',
      false: '',
    },
  },
} as const;

export const DealsBoxWrapper = ({ limitMaxWidth, children, ...rest }: ComponentPropsWithoutRef<'section'> & { limitMaxWidth?: boolean }) => {
  return (
    <section
      className={classNames(
        'mx-0 mt-[-1px] flex w-full place-content-center bg-mglGray50 px-0 py-10',
        limitMaxWidth != undefined && variants.DealsBoxWrapper.limitMaxWidth[limitMaxWidth.toString()]
      )}
      {...rest}>
      {children}
    </section>
  );
};

export const DealsBoxContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex w-full flex-col gap-y-4" {...rest}>
      {children}
    </div>
  );
};

export const DealsBoxTitleContainer = ({ shouldRenderAds, children, ...rest }: ComponentPropsWithoutRef<'div'> & { shouldRenderAds?: boolean }) => {
  return (
    <div className={shouldRenderAds != undefined && variants.DealsBoxTitleContainer.noPadding[(!shouldRenderAds).toString()]} {...rest}>
      {children}
    </div>
  );
};

export const DealsBoxTitle = ({ children, ...rest }: ComponentPropsWithoutRef<'h4'>) => {
  return (
    <h4 className="text-2xl font-medium text-mglGray900" {...rest}>
      {children}
    </h4>
  );
};

export const DealsBoxSubheading = ({ children, ...rest }: ComponentPropsWithoutRef<'p'>) => {
  return (
    <p className="pt-1 text-sm text-mglGray900" {...rest}>
      {children}
    </p>
  );
};

export const DealsChipButtonContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'aside'>) => {
  return (
    <aside
      className="mt-4 flex self-end [&>div>span>svg>path]:border [&>div>span>svg>path]:border-solid [&>div>span>svg>path]:border-mglGray50 [&>div>span>svg>path]:stroke-[3] [&>div]:border [&>div]:border-solid [&>div]:border-mglGray400 [&>div]:px-6 [&>div]:py-0 [&>div]:transition-all [&>div]:duration-300 [&>div]:ease-ease [&>div]:hover:border-mglGray900"
      {...rest}>
      {children}
    </aside>
  );
};

export const DealsUnitContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'a'>) => {
  return (
    <a
      className="[&.chip-button>span>svg]:bg-MglGreen600 relative flex h-full w-full items-center gap-x-4 rounded bg-mglGray50 p-4 shadow-mdc-elevation-z9-3 transition-shadow duration-300 ease-ease hover:shadow-mdc-elevation-z4-3 [&.chip-button>span>svg]:text-mglGray50 [&.chip-button>svg>path]:border-[1.5px] [&.chip-button>svg>path]:border-solid [&.chip-button>svg>path]:border-mglGray50 [&.chip-button>svg>path]:stroke-[3] [&.chip-button>svg]:transition-all [&.chip-button>svg]:duration-300 [&.chip-button>svg]:ease-ease [&.chip-button]:bg-mglGreen600 [&>svg]:last:text-xl"
      {...rest}>
      {children}
    </a>
  );
};

export const DealsProductImageContainer = ({ children, className, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={classNames('flex flex-col justify-between [&_img]:h-[76px] [&_img]:w-[76px] [&_img]:rounded', className)} {...rest}>
      {children}
    </div>
  );
};

export const DealsRow = ({ children, isProduct, ...rest }: ComponentPropsWithoutRef<'div'> & { isProduct?: boolean }) => {
  return (
    <div
      className={classNames(
        'flex w-full items-start justify-between gap-4 tablet:items-center',
        isProduct != undefined && variants.DealsRow.isProduct[isProduct.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const DealsColumn = ({ children, fullWidth, isProduct, ...rest }: ComponentPropsWithoutRef<'div'> & { fullWidth?: boolean; isProduct?: boolean }) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-y-2 [&_.chip-button]:gap-2 [&_.chip-button]:transition-all [&_.chip-button]:duration-300 [&_.chip-button]:ease-ease [&_.chip-button_path]:transition-all [&_.chip-button_path]:duration-300 [&_.chip-button_path]:ease-ease',
        isProduct != undefined && variants.DealsColumn.isProduct[isProduct.toString()],
        fullWidth != undefined && variants.DealsColumn.fullWidth[fullWidth.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const DealsPartnerContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex w-full items-center justify-between gap-x-6 phone:w-auto phone:justify-start" {...rest}>
      {children}
    </div>
  );
};

export const DealsProductDescription = ({ children, inSwiper, ...rest }: ComponentPropsWithoutRef<'p'> & { inSwiper?: boolean }) => {
  return (
    <p
      className={classNames(
        'line-clamp-3  max-h-[2.8em] w-full text-ellipsis text-sm font-normal leading-[1.4em] text-mglGray800  tablet:text-base',
        inSwiper != undefined && variants.DealsProductDescription.inSwiper[inSwiper.toString()]
      )}
      {...rest}>
      {children}
    </p>
  );
};

export const DealsValueContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex flex-col justify-between gap-x-4 whitespace-nowrap phone:justify-start phone:gap-x-0" {...rest}>
      {children}
    </div>
  );
};

export const DealsValue = ({ children, ...rest }: ComponentPropsWithoutRef<'p'>) => {
  return (
    <p className="text-lg font-medium leading-tight text-mglBlue400" {...rest}>
      {children}
    </p>
  );
};

export const DealsSecondaryValue = ({ children, discount, ...rest }: ComponentPropsWithoutRef<'p'> & { discount?: boolean }) => {
  return (
    <p
      className={classNames('text-xs leading-tight text-mglBlue400', discount != undefined && variants.DealsSecondaryValue.discount[discount.toString()])}
      {...rest}>
      {children}
    </p>
  );
};

export const DealsCents = ({ children, ...rest }: ComponentPropsWithoutRef<'sup'>) => {
  return (
    <sup className="align-text-top text-xs" {...rest}>
      {children}
    </sup>
  );
};

export const DealsContentContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="mb-6 [&>h4]:text-lg [&>h4]:desktop:text-2xl" {...rest}>
      {children}
    </div>
  );
};

export const DealsContentTitle = ({ children, ...rest }: ComponentPropsWithoutRef<'h4'>) => {
  return (
    <h4 className="mb-4 font-bold text-mglGray900 [&#title-oferta]:text-2xl" {...rest}>
      {children}
    </h4>
  );
};

export const DealsContentWrapper = ({ children, ...rest }: ComponentPropsWithoutRef<'ul'>) => {
  return (
    <ul className="flex flex-col rounded-lg border border-solid border-mglGray200 bg-white px-4 py-0 shadow-mdc-elevation-z9-3" {...rest}>
      {children}
    </ul>
  );
};

export const DealsContentLink = ({ children, className, ...rest }: ComponentPropsWithoutRef<'a'>) => {
  return (
    <a
      className={classNames(
        'flex w-full items-center justify-between gap-4 gap-x-2 gap-y-4 phone:gap-0 [&_figure>div>img]:rounded [&_figure]:shrink-0 [&_figure]:grow-0 [&_figure]:basis-[76px]',
        className
      )}
      {...rest}>
      {children}
    </a>
  );
};

export const DealsContentUnitContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'li'>) => {
  return (
    <li
      className="block list-none border-x-0 border-b-0 p-2 px-0 py-4 [&+li]:border-t [&+li]:border-solid [&+li]:border-mglGray100  [&.bloco-zoom>a]:shadow-none [&.bloco-zoom>a]:hover:shadow-none [&.bloco-zoom]:m-0"
      {...rest}>
      {children}
    </li>
  );
};

export const DealsContentTextContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="flex w-full flex-col items-center justify-between gap-4 gap-x-2 gap-y-4 phone:flex-row phone:gap-0 [&>.chip-button]:w-full [&>.chip-button]:font-medium [&>.chip-button]:phone:w-auto [&_figure>div>img]:rounded [&_figure]:shrink-0 [&_figure]:grow-0 [&_figure]:basis-[76px]"
      {...rest}>
      {children}
    </div>
  );
};

export default {};
