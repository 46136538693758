import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import WrapperDestaque from '@components/Layout/WrapperDestaque';
import Titulo from '@/components/Layout/Title';
import useAmpComponent from '@/hooks/useAmpComponent';
import { getCurrentHub } from '@sentry/nextjs';
import useBreakpoint from '@/hooks/useBreakpoint';
import classNames from '@/utils/className';
import Card from '@/components/Layout/Card';

SwiperCore.use([Navigation, Pagination]);

type HighlightVideo = {
  titulo: string;
  categoria: string;
  url: string;
  imagem: {
    url: string;
    blurhash: string;
  };
};
export interface VideoSlideProps {
  featuredVideosData: HighlightVideo[];
  isOnProduct?: boolean;
  setBackgroundColor?: string;
  useTitle?: boolean;
}

export default function VideoSliders({ featuredVideosData, isOnProduct = false, setBackgroundColor = 'bg-mglGray50', useTitle = true }: VideoSlideProps) {
  const variants = {
    TituloContent: {
      isOnProduct: {
        true: 'max-w-[initial]',
        false: 'max-w-[calc(100%-3rem)] tablet:max-w-[83.33%]',
      },
    },
  } as const;

  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const videoSlidersSpan = currentSpan && currentSpan.startChild({ op: 'videoSliders' });

  const [isMobile] = useBreakpoint('phone');

  const { ImageComponent } = useAmpComponent();
  const pagination = {
    clickable: true,
  };

  const navigation = {
    nextEl: '#videos-swiper-button__next',
    prevEl: '#videos-swiper-button__prev',
  };

  return (
    <>
      <WrapperDestaque backgroundColor={setBackgroundColor || 'bg-mglGray50'} paddingOnlyTop={isOnProduct}>
        <div className={classNames('mx-auto my-0 w-full', variants.TituloContent.isOnProduct[isOnProduct.toString()])}>
          <Titulo texto="Vídeos" urlRedirect="/video/" color="black" showChip={!isOnProduct} alignRight />
        </div>
        <div className="relative [&_.container-swiper\_\_next]:right-6 [&_.container-swiper\_\_prev]:left-6 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-ease [&_.swiper-pagination]:bottom-0 [&_.swiper-pagination]:pr-[8.333%] [&_.swiper-pagination]:text-right [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:w-[26px] [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-mglBlue400 [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s]">
          <Swiper
            centeredSlidesBounds
            className="py-0"
            centeredSlides={isMobile}
            grabCursor
            speed={1000}
            pagination={pagination}
            navigation={navigation}
            breakpoints={{
              0: {
                slidesPerView: 1.1,
                spaceBetween: 16,
                slidesPerGroup: 1,
              },
              530: {
                slidesPerView: 2,
                spaceBetween: 16,
                slidesPerGroup: 2,
              },
              980: {
                slidesPerView: isOnProduct ? 3 : 4,
                spaceBetween: 16,
                slidesPerGroup: isOnProduct ? 3 : 4,
              },
            }}>
            {featuredVideosData.map(video => (
              <SwiperSlide key={`video-sliders-${video.url}`}>
                <div className="relative h-[300px] w-full overflow-hidden rounded-lg">
                  <Card title={video.titulo} image={video.imagem} url={video.url} onlyImage={!useTitle} onlyImageAndText={useTitle} fullWidth />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="container-swiper container-swiper__prev">
            <div id="videos-swiper-button__prev" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(180deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
          <div className="container-swiper container-swiper__next">
            <div id="videos-swiper-button__next" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(360deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      </WrapperDestaque>
      {videoSlidersSpan && videoSlidersSpan.finish()}
    </>
  );
}
