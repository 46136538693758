import WrapperContent from '@components/Layout/WrapperContent';
import NewsTimeLine, { PropsTitle } from '@/components/Layout/NewsTimeLine';
import { TimeLine } from '@/types/types';
import Titulo from '@/components/Layout/Title';
import { mglBlue, mglGray } from '@/styles/designSystemColors';

type FeaturedCategoryContent = {
  nome: string;
  titulo: string;
  url: string;
  itens: TimeLine[];
};

export interface FeaturedCategoryProps {
  featuredCategory: FeaturedCategoryContent;
}

export default function FeaturedCategory({ featuredCategory }: FeaturedCategoryProps) {
  const PropTitle: PropsTitle = {
    color: mglGray.mglGray50,
  };

  return (
    <WrapperContent backgroundColor={mglBlue.mglBlue400} paddingOnBottom>
      <Titulo texto={featuredCategory.nome} urlRedirect={featuredCategory.url} color="white" alignRight showChip />
      <div className="ml-0 grid grid-cols-1 grid-rows-5 gap-5 [grid-auto-columns:1fr] tablet:grid-cols-2 tablet:grid-rows-3 desktop:grid-cols-3 desktop:grid-rows-2">
        {featuredCategory.itens.map(n => (
          <NewsTimeLine news={n} key={n.url} propsTitle={PropTitle} headingTag="h3" />
        ))}
      </div>
    </WrapperContent>
  );
}
