import Link from 'next/link';
import ImageComponent from '../Image';
import Chip from '@/components/Layout/Chip';
import classNames from '@/utils/className';

interface CardProps {
  title: string;
  url: string;
  image: {
    url: string;
    blurhash: string;
  };
  rumor?: boolean;
  chipText?: string;
  onlyImage?: boolean;
  onlyImageAndText?: boolean;
  fullWidth?: boolean;
}

export default function Card({
  title,
  url,
  image,
  chipText = 'ver detalhes',
  rumor = false,
  onlyImage = false,
  onlyImageAndText = false,
  fullWidth = false,
}: CardProps) {
  return (
    <Link href={url} passHref>
      <a
        className={classNames(
          'group flex min-h-[220px] w-full cursor-pointer flex-col items-center rounded-lg bg-mglGray50 no-underline transition-all duration-300',
          onlyImage || onlyImageAndText ? 'p-0' : 'p-4',
          fullWidth ? 'w-full' : ''
        )}
        title={title}>
        <div
          className={classNames(
            'relative flex aspect-video justify-center [&>figure>div>img]:object-contain',
            fullWidth ? 'w-full' : onlyImageAndText || onlyImage ? 'max-h-[240px]' : 'max-h-[120px] '
          )}>
          <ImageComponent
            src={image.url}
            blurhash={image.blurhash}
            width={onlyImage || onlyImageAndText ? null : 100}
            height={onlyImage || onlyImageAndText ? null : 100}
            alt={title}
          />
          {rumor && !onlyImage && !onlyImageAndText && (
            <div className="absolute bottom-[9.5em] left-0 right-0 m-auto w-[3.375rem] rounded-lg bg-[#e8384f] text-center text-[0.6875rem] uppercase leading-loose text-mglGray50 hover:[&>div>span>svg>path>fill]:text-mglGray50">
              Rumor
            </div>
          )}
        </div>
        {onlyImageAndText && <h3 className={'mt-4 font-medium text-mglGray900 tablet:mx-0 tablet:mb-0'}>{title}</h3>}
        {!onlyImage && !onlyImageAndText && (
          <div className="flex h-10 items-center justify-center text-center text-sm font-medium text-mglGray900 no-underline">{title}</div>
        )}
        {!onlyImage && !onlyImageAndText && (
          <Chip
            texto={chipText}
            chipSize="medium"
            posIcon="chevron-right"
            color="black"
            borderColor="black"
            title={title}
            className="group-hover:border group-hover:border-mglGray900 group-hover:bg-mglGray900 group-hover:text-mglGray50 [&>span>svg]:transition-colors group-hover:[&>span>svg]:text-mglGray50"
          />
        )}
      </a>
    </Link>
  );
}
